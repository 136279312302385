import { isNil } from "lodash";
import { SignalColors, TemperatureColors } from "./types";

type UnknownData = Record<string, unknown>;

export interface Sensor extends UnknownData {
  id: string;
  name: string;
  position: {
    x: number;
    z: number;
    elevation: number;
    levelIndex: number;
  };
}
export interface SmplrCoord2d {
  x: number;
  z: number;
  levelIndex: number;
}

export const getSensorsFromDb = async (groupNodesData: any, groupGatewaysData: any) => {
  const sensors: Sensor[] = [];
  groupGatewaysData?.map((spaceGateway: any) => {
    if (isNil(spaceGateway.mappingCoordinates)) return true;
    const sensor: Sensor = {
      id: spaceGateway.gatewayName,
      name: spaceGateway.gatewayName,
      position: spaceGateway.mappingCoordinates.coordinates[0],
    };
    sensors.push(sensor);
    return true;
  });
  groupNodesData?.map((spaceNode: any) => {
    spaceNode.rooms.map((roomNode: any) => {
      roomNode.slotMappings.map((slotMapping: any) => {
        if (isNil(slotMapping)) return true;
        if (isNil(slotMapping.slot.mappingCoordinates)) return true;
        const sensor: Sensor = {
          id: slotMapping.slot.id,
          name: slotMapping.slotName,
          position: slotMapping.slot.mappingCoordinates.coordinates[0],
        };
        sensors.push(sensor);
        return true;
      });
      return true;
    });
    return true;
  });
  return sensors;
};

export const getKeysCoordinates = async (groupNodesData: any) => {
  const keyMapping: any[] = [];
  groupNodesData?.map((spaceNode: any) => {
    spaceNode.rooms.map((roomNode: any) => {
      if (roomNode.mappingCoordinates.length > 0) {
        const sensor = {
          id: roomNode.positionId,
          name: roomNode.positionName,
          position: roomNode.mappingCoordinates[0].coordinates,
        };
        keyMapping.push(sensor);
        return true;
      }
      return true;
    });
    return true;
  });
  return keyMapping;
};

export const getColor = (value: number) => {
  const newValue = Math.ceil(value / 10);
  switch (newValue) {
    case 1:
      return SignalColors.LEVEL1;
    case 2:
      return SignalColors.LEVEL2;
    case 3:
      return SignalColors.LEVEL3;
    case 4:
      return SignalColors.LEVEL4;
    case 5:
      return SignalColors.LEVEL5;
    case 6:
      return SignalColors.LEVEL6;
    case 7:
      return SignalColors.LEVEL7;
    case 8:
      return SignalColors.LEVEL8;
    case 9:
      return SignalColors.LEVEL9;
    case 10:
      return SignalColors.LEVEL10;
    default:
      return SignalColors.LEVEL1;
  }
};

export const getHumidityColor = (value: number) => {
  const newValue = Math.ceil(value / 10);
  switch (newValue) {
    case 1:
      return SignalColors.LEVEL10;
    case 2:
      return SignalColors.LEVEL9;
    case 3:
      return SignalColors.LEVEL8;
    case 4:
      return SignalColors.LEVEL7;
    case 5:
      return SignalColors.LEVEL6;
    case 6:
      return SignalColors.LEVEL5;
    case 7:
      return SignalColors.LEVEL4;
    case 8:
      return SignalColors.LEVEL3;
    case 9:
      return SignalColors.LEVEL2;
    case 10:
      return SignalColors.LEVEL1;
    default:
      return SignalColors.LEVEL10;
  }
};

export const getTemperatureColor = (value: number) => {
  const newValue = Math.ceil((value / 50) * 10);
  switch (newValue) {
    case 1:
      return TemperatureColors.LEVEL1;
    case 2:
      return TemperatureColors.LEVEL2;
    case 3:
      return TemperatureColors.LEVEL3;
    case 4:
      return TemperatureColors.LEVEL4;
    case 5:
      return TemperatureColors.LEVEL5;
    case 6:
      return TemperatureColors.LEVEL6;
    case 7:
      return TemperatureColors.LEVEL7;
    case 8:
      return TemperatureColors.LEVEL8;
    case 9:
      return TemperatureColors.LEVEL9;
    case 10:
      return TemperatureColors.LEVEL10;
    default:
      return TemperatureColors.LEVEL1;
  }
};
